import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/aRzH1SwYQyc">
    <SEO title="Friends Day - Friends Day" />
  </Layout>
)

export default SermonPost
